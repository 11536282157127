import React, { useEffect, useState } from "react";
import { Layout } from "../components/layouts/Layout";
import axios from "axios";
import Container from "../components/ui/Container";
import ReportsTable from '../components/reports/ReportsTable';

function ReportsPage() {
    const [reportData, setReportEvent] = useState([])
    function getEvents() {
        axios.get("/api/aws/getvideodata")
        .then((res) => {
            setReportEvent(res?.data?.videosarray);
            console.log("res", res);
          })
          .catch((err) => {
            console.log(err);
            
          });
    }
    useEffect(()=>{
        getEvents();
    },[])
    return (
        <Layout>
            <section>
                <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
                    <div
                    className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                    aria-hidden="true"
                    />
                        <div className='py-10 md:py-20'>
                            <Container>
                                <h1 className="text-3xl font-medium tracking-tight text-gray-900 sm:leading-tight sm:text-3xl lg:col-span-2 xl:col-auto">My Reports</h1>
                                {reportData?.length > 0 ? <ReportsTable reportData={reportData} /> : <p className="my-4">You have no reports at this time</p>}

                            </Container>
                        </div>
                    
                </div>
            </section>
        </Layout>
    );
}

export default ReportsPage;
