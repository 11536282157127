import React, { useState } from 'react';
import Swal from "sweetalert2";
import axios from "axios";

const ReportsTable = ({ reportData }) => {
  const [hidden, setHidden] = useState({});
  const [preIndex, setPreIndex] = useState('');
  const catagories = {
    Summary: {
      label: "Summary & statistics",
    },
    Flowquality: {
      label: "Flow Quality",
    },
    Security: {
      label: "Security",
    },
    Performance: {
      label: "Performance",
    },
    Upgrade: {
      label: "Upgrade effort (latest release)",
    },
    Dependency: {
      label: "System Dependency Analysis",
    },
    Bestpractices: {
      label: "Best practices",
    }
  }
    const columns = [
        { id: 'fileName', label: 'Label', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        {
          id: 'email',
          label: 'Email',
          minWidth: 370,
        },
        {
          id: 'status',
          label: 'Status',
          minWidth: 270,
        },
        {
          id: 'actions',
          label: 'Actions',
          minWidth: 170,
        }
      ];
      const createData = (fileName, date , email, status, files, id, systeminformation) => {
        return { fileName, date , email, status, files, id, systeminformation };
     }
      const rows = [
      ];
      for (let i =0; i < reportData.length; i++) {
        const dbdate = new Date(reportData[i].createdAt);
        let formattedDate = dbdate.toLocaleString();
        formattedDate = formattedDate.split(' ')[0].split(',')[0];
        rows.push(createData(
          reportData[i].label,
          formattedDate,
          reportData[i].email,
          reportData[i].status === 'processed' ? 'Processed' : 'Pending',
          reportData[i].reports,
          reportData[i]._id,
          reportData[i].systeminformation,
          )
      )
        
      }
      const deleteDataEvent = (e, data, index) => {
        Swal.fire({
            title: "Are you sure to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
               await axios.delete('/api/aws/deletevideodata', { data: {id: data.id} }).then(
                    console.log('test')
                  )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: `Successfully Deleted`,
                type: 'sucess',
                preConfirm: async () => {
                    window.location.reload();
                 },
              });
            }
          });
        };
    const expandData = index => {
      console.log(index, preIndex)
      setHidden({ ...hidden, [index]: !hidden[index], [preIndex]: !hidden[preIndex] });
      index !== preIndex ? setPreIndex(index) : setPreIndex(null)
    };
    const renderSystemInformation = data => {
      return(
        <div className='my-2'>
          <ul>
          {data.map((info) => <li className='my-1 list-disc mx-8 text-base leading-7 text-gray-600'>{catagories[info.sysinfo].label}</li>)}
          </ul>
        </div>
        
      )
    }


    const renderFileInformation = data => {
      const extractFilename = url => {
        // Use regex to extract the filename from the URL
        const match = url.match(/\/([^\/?#]+)(?:[?#]|$)/);
        return match ? match[1] : 'download';
      };

      return (
        <div className='flex flex-col'>
          {data.map((file) => (
            <p key={file.url} className='my-1 text-base leading-7 text-gray-600'>
              <span>{file.filename}</span>
              <span className='mx-6'>
                <a className='text-indigo-600' href={file.url} download>
                  {extractFilename(file.url)}
                </a>
              </span>
            </p>
          ))}
        </div>
      );
    };

/*
    const renderFileInformation = data => {
      return(
        <div className='flex flex-col'>
          {data.map((file) => <p className='my-1 text-base leading-7 text-gray-600'><span>{file.filename}</span><span className='mx-6'><a className='text-indigo-600' href={file.url} download>download</a></span></p>)}
        </div>
        
      )
    }
*/
  return (
    <div className='py-6 md:py-6'>
        <div className='font-medium tracking-tight text-gray-900 text-left w-full' style={{boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'}} >
          <div className='w-full'>
            <div className='flex w-full border-b border-t font-bold border-slate-200 bg-[#f5f5f5]'>
                {columns.map((column) => (
                    <div
                    key={column.id}
                    align={column.align}
                    className='p-4 w-1/3 text-base font-semibold text-gray-900'
                    style={{ minWidth: column.minWidth }}
                    >
                    {column.label}
                    </div>
                ))}
            </div>
          </div>
          <div className='flex flex-col items-center overflow-y-scroll w-full max-h-200'>
            {rows
              .map((row, index) => {
                return (
                  <>
                    <div className={`flex w-full ${!hidden[index] ? 'border-b' : ''}  border-slate-200`}  hover role="checkbox" tabIndex={-1} key={row.code} >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <div className='p-4  w-1/3 text-gray-600 text-base' 
                        style={{ minWidth: column.minWidth }} key={column.id} align={column.align}>
                          {column.id !== 'actions' ? value : 
                          <div >
                            {row.status === 'Processed' && <span className='cursor-pointer text-indigo-600' onClick={(e) => expandData(index)}>{!hidden[index] ? 'show | ' : 'hide | '}</span>}<span className='cursor-pointer text-indigo-600' onClick={(e) => deleteDataEvent(e, row, index)}>delete</span>
                          </div>
                          }
                        </div>
                      );
                    })}
                    </div>
                    <div className={`py-4 px-4 flex-row w-full border-b border-slate-200 ${!hidden[index] ? 'hidden' : 'showContent'}`}>
                      <div>
                      <b className='text-base font-semibold leading-7 text-gray-900'>Selected Categories:</b>
                      {renderSystemInformation(row.systeminformation)}
                      </div>
                      <div className='my-4'>
                      <b className='text-base font-semibold leading-7 text-gray-900'>Free Sample Reports:</b>
                      <div className='mx-4'>
                      {renderFileInformation(row.files)}
                      For additional reports, please contact us at contact@nifi-services.com
                      </div>
                      </div>
                    </div>
                </>
                );
              })}
          </div>
        </div>
    </div>
  );
}

export default ReportsTable;
